import * as React from "react";

const Footer = () => {
  return (
    <>
      <footer id="colophon" style={{ backgroundImage: "url(data:image/gif;base64,R0lGODlhAQABAIABAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==), url(https://www.conveyancing-online.co.uk/wp-content/uploads/2023/03/footer-background.svg)" }} className="site-footer ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footer_image_wrap">
                <img title="footer-image" alt="footer-image" width="229" height="72" className="" decoding="async" id="MTcyOjE2Ng==-1" src="https://www.conveyancing-online.co.uk/wp-content/uploads/2023/03/footer-image.svg" />
              </div>
            </div>
          </div>
          <div className="row footer_menu_and_content_wrap">
            <div className="footer_content_wrap footer_col col-lg-4">
              <div className="footer-content">
                <h6>Your Online Conveyancing Solicitor</h6>
                <p>We Offer An Online Conveyancing System With Experienced Conveyancing Solicitors And A Support Team To Make Buying, Selling, And Remortgaging Less Stressful.</p>
              </div>
              <div className="sm_wrap">
                <div className="sm_image_and_link_wrap">
                  <div className="image_wrap">
                    <a className="button" href="https://www.facebook.com/theconveyancingteam" target="_blank" rel="noreferrer">
                      <img width="26" height="28" alt="facebook" title="facebook" className="" decoding="async" id="MTkwOjE4OA==-1" src="https://www.conveyancing-online.co.uk/wp-content/uploads/2023/03/facebook.svg" />
                    </a>
                  </div>
                  <div className="image_wrap">
                    <a className="button" href="mailto:info@conveyancing-online.co.uk" target="">
                      <img width="26" height="21" alt="email" title="email" className="" decoding="async" id="MTk4OjE3OQ==-1" src="https://www.conveyancing-online.co.uk/wp-content/uploads/2023/03/email.svg" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer_col  services col-lg-4">
              <h6>Our Conveyancing Services</h6>
              <div className="menu-our-conveyancing-services-container">
                <ul id="footer-menu-1" className="menu ">
                  <li id="menu-item-587" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-587">
                    <a target="_blank" rel="noopener noreferrer" href="https://connect.conveyancing-online.co.uk">
                      Selling &amp; Buying
                    </a>
                  </li>
                  <li id="menu-item-588" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-588">
                    <a target="_blank" rel="noopener noreferrer" href="https://connect.conveyancing-online.co.uk">
                      Selling
                    </a>
                  </li>
                  <li id="menu-item-589" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-589">
                    <a target="_blank" rel="noopener noreferrer" href="https://connect.conveyancing-online.co.uk">
                      Buying
                    </a>
                  </li>
                  <li id="menu-item-590" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-590">
                    <a target="_blank" rel="noopener noreferrer" href="https://connect.conveyancing-online.co.uk">
                      Remortgage
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer_col quick_links col-lg-4">
              <h6 className="">Quick Links</h6>
              <div className="menu-quick-links-container">
                <ul id="footer-menu-2" className="menu">
                  <li id="menu-item-861" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-861">
                    <a href="https://www.conveyancing-online.co.uk/">Home</a>
                  </li>
                  <li id="menu-item-582" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-582">
                    <a href="https://www.conveyancing-online.co.uk/blog/">Blog</a>
                  </li>
                  <li id="menu-item-583" className="menu-item menu-item-type-post_type menu-item-object-page">
                    <a href="https://www.conveyancing-online.co.uk/about-us/">About Us</a>
                  </li>
                  <li id="menu-item-584" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-584">
                    <a href="https://www.conveyancing-online.co.uk/contact-us/">Contact Us</a>
                  </li>
                  <li id="menu-item-585" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-585">
                    <a href="https://www.conveyancing-online.co.uk/faqs/">FAQS</a>
                  </li>
                  <li id="menu-item-586" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-586  current-menu-item page_item page-item-48 current_page_item menu-item-586">
                    <a target="_blank" rel="noopener noreferrer" href="https://connect.conveyancing-online.co.uk" aria-current="page">
                      Instant Quotes
                    </a>
                  </li>
                  <li id="menu-item-1183" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1183">
                    <a href="https://www.conveyancing-online.co.uk/sitemap/">Sitemap</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <div className="col-lg-12">
              <div className="copyright_content">
                <p>©Copyright 2024. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
