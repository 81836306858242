import * as React from "react";
import { useAppDispatch, useAppSelector } from "../store/store";
import ResultSummaryPanel from "../components/ResultSummaryPanel";
import Header from "../components/Nav";
import SubHeader from "../components/SubHeader";
import Footer from "../components/Footer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { getBaseURL } from "../utils";
import { useEffect, useState } from "react";
import { setMultiQuoteState } from "../store/quoteSlice";

const LeadResultPage = () => {
  const multiquote = useAppSelector((state) => state.quoteReducer.multiquote);
  const navigate = useNavigate();
  const [fetching, setFetching] = useState<boolean>(false);
  const [leadId, setLeadId] = useState<number>();
  const [leadUuid, setLeadUuid] = useState<string>();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let lid;
    const l = searchParams.get("l");
    if (l) {
      try {
        lid = Number(l);
      } catch (e) {
        console.error("Error reading lead ID : ", e);
      }
    }
    setLeadId(lid);

    const uid = searchParams.get("uid");
    if (uid) {
      setLeadUuid(uid);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!!leadId && !!leadUuid) {
      const url = getBaseURL() + "/searchpoint/rest/customerquote/lead-result?l=" + leadId + "&uid=" + leadUuid;
      setFetching(true);
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log("Retrieved Data : ", data);
          dispatch(setMultiQuoteState(data));
          console.log("Done Updating : ");
          setFetching(false);
        })
        .catch((err) => {
          console.log("Error: ", err.message);
          setFetching(false);
        });
    }
  }, [leadId, leadUuid]);

  //console.log("multiquote = ", multiquote);

  return (
    <>
      <Header />
      <SubHeader activeStep={2} />
      <div className="container mx-auto ">
        <div className="max-w-none prose prose-sm">
          {fetching && (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} className="py-5">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}

          {!fetching && !!multiquote && (
            <div className="mb-5">
              <h3>Quotes</h3>
              {!multiquote.results ||
                (!multiquote.results.length && (
                  <>
                    <p>No results found; </p>
                  </>
                ))}
              {!!multiquote.results && !!multiquote.results.length && (
                <>
                  <p>Our top {multiquote.results.length} quotes are shown below; </p>

                  {multiquote.results.map((thisResult) => (
                    <ResultSummaryPanel key={thisResult.organisationId} result={thisResult} summary={true} />
                  ))}
                </>
              )}
            </div>
          )}
        </div>
        <hr className="opacity-100 mb-3" />

        <div className="flex mb-5" style={{ justifyContent: "space-between" }}>
          <div>
            <Button
              type="button"
              style={{ color: "white" }}
              className="btn btn-secondary btn-outline"
              onClick={() => {
                navigate("/quote");
              }}>
              <i className="bi bi-caret-left-fill"></i>&nbsp;Restart
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LeadResultPage;
