import * as React from "react";

import { Button, Container, Nav, Navbar } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const Header = () => {
  return (
    <Navbar expand="lg" style={{ height: 95, backgroundColor: "transparent" }}>
      <Container>
        <Navbar.Brand href="#home">
          <img src="The-Conveyancing-Team-Logo.svg" style={{ maxWidth: 250 }} className="d-inline-block align-top" alt="Conveyamcing Team Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="https://www.conveyancing-online.co.uk/">Home</Nav.Link>
            <Nav.Link href="https://www.conveyancing-online.co.uk/about-us/">About Us</Nav.Link>
            <Nav.Link href="https://www.conveyancing-online.co.uk/services/">Conveyancing Services</Nav.Link>
            <Nav.Link href="https://www.conveyancing-online.co.uk/faqs/">FAQs</Nav.Link>
            <Nav.Link href="https://www.conveyancing-online.co.uk/blog/">BLOG</Nav.Link>
            <Nav.Link href="https://www.conveyancing-online.co.uk/contact-us/">Contact Us</Nav.Link>
          </Nav>

          <Form>
            <Button type="submit" variant="secondary">
              INSTANT QUOTES
            </Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
