import React from "react";

import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import { MultiQuoteResponse, QuoteRequest } from "../types";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useAppDispatch, useAppSelector } from "../store/store";
import { setMultiQuoteState } from "../store/quoteSlice";
import { useNavigate } from "react-router-dom";
import { setSubmissionDataState } from "../store/submissionSlice";
import Header from "../components/Header";
import SubHeader from "../components/SubHeader";
import Footer from "../components/Footer";
import "bootstrap-icons/font/bootstrap-icons.css";
import { getBaseURL } from "../utils";

const QuotePage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const navigate = useNavigate();

  const submissionData = useAppSelector((state) => state.submissionReducer.submissionData);
  const forcedQuoteId = useAppSelector((state) => state.quoteReducer.forcedQuoteId);
  const forcedQuoteUuid = useAppSelector((state) => state.quoteReducer.forcedQuoteUuid);
  const dispatch = useAppDispatch();

  const handleSubmit = async (values: QuoteRequest, formik: FormikHelpers<QuoteRequest>) => {
    console.log("Submitted : ", JSON.stringify(values, null, 2));

    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("yourAction");

    console.log("handlSubmit: token = ", token);

    const refreshedValues = { ...values, gRecaptchaResponse: token };

    formik.setSubmitting(true);
    dispatch(setSubmissionDataState(refreshedValues));
    let url = getBaseURL() + "/searchpoint/rest/customerquote/multiquote";

    if (!!forcedQuoteId && !!forcedQuoteUuid) {
      url = url + "?fqid=" + forcedQuoteId;
      url = url + "&fquuid=" + forcedQuoteUuid;
    }
    console.log("Values Now : ", JSON.stringify(refreshedValues, null, 2));
    console.log("url Now : ", url);
    fetch(url, {
      method: "post",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(refreshedValues)
    })
      .then((response) => response.json())
      .then((responseJson: MultiQuoteResponse) => {
        console.log("responseJson: ", responseJson);
        formik.setSubmitting(false);
        dispatch(setMultiQuoteState(responseJson));
        navigate("/lead-result?l=" + responseJson.leadId + "&uid=" + responseJson.leadUuid);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        return responseJson;
      })
      .catch((error) => {
        console.error(error);
        formik.setSubmitting(false);
      });
  };

  const validationSchema = yup.object({
    contactName: yup.string().required("Name is required"),
    contactEmail: yup.string().email("Please enter a valid email address").required("Email is required")
  });

  if (!submissionData) {
    navigate("/quote");
    return <></>;
  }

  return (
    <>
      <Header />
      <SubHeader activeStep={1} />
      <div className="container ">
        <div>
          <div>
            <h3>Contact Details</h3>
            <h5>Please enter your contact details below.</h5>
            <p>We require your contact details to enable us to send you the quotes.</p>
          </div>

          <Formik initialValues={submissionData} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {(formik) => (
              <Form onSubmit={formik.handleSubmit} className="form-horizontal">
                <Row>
                  <Col sm={12} className="well shadow mt-4">
                    <h5>Your Contact Details</h5>

                    <div>
                      <Form.Group as={Row} className="mt-4">
                        <Form.Label column sm={2}>
                          Name:
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="text" name="contactName" placeholder="Enter your name..." value={formik.values.contactName} onChange={formik.handleChange} isInvalid={!!formik.touched.contactName && !!formik.errors.contactName} />
                          <Form.Text className="text-danger">{formik.touched.contactName && formik.errors.contactName ? <div className="text-danger">{formik.errors.contactName}</div> : null}</Form.Text>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mt-4">
                        <Form.Label column sm={2}>
                          Telephone:
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="text" name="contactTelephone" placeholder="Enter your telephone number..." value={formik.values.contactTelephone} onChange={formik.handleChange} isInvalid={!!formik.touched.contactTelephone && !!formik.errors.contactTelephone} />
                          <Form.Text className="text-danger">{formik.touched.contactTelephone && formik.errors.contactTelephone ? <div className="text-danger">{formik.errors.contactTelephone}</div> : null}</Form.Text>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mt-4">
                        <Form.Label column sm={2}>
                          Email:
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="email" name="contactEmail" placeholder="Enter your email..." value={formik.values.contactEmail} onChange={formik.handleChange} isInvalid={!!formik.touched.contactEmail && !!formik.errors.contactEmail} />
                          <Form.Text className="text-danger">{formik.touched.contactEmail && formik.errors.contactEmail ? <div className="text-danger">{formik.errors.contactEmail}</div> : null}</Form.Text>
                          <p className="small italic mt-1">
                            Your data will be handled in accordance with our{" "}
                            <a href="https://www.searchpoint.co.uk/resources/pp2.pdf" target="_BLANK" rel="noreferrer">
                              Privacy Policy.
                            </a>
                          </p>
                        </Col>
                      </Form.Group>
                    </div>

                    <hr />
                    <Form.Group as={Row}>
                      <Form.Label column sm={2}>
                        Referral Code:
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control type="email" name="referralCode" placeholder="Enter a referral code (optional)..." value={formik.values.referralCode} onChange={formik.handleChange} isInvalid={!!formik.touched.referralCode && !!formik.errors.referralCode} />
                        <Form.Text className="text-danger">{formik.touched.referralCode && formik.errors.referralCode ? <div className="text-danger">{formik.errors.referralCode}</div> : null}</Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>

                  <Form.Group as={Row} className="mt-3">
                    <Col sm={{ span: 8, offset: 2 }}>
                      <div style={{ display: "flex", justifyContent: "center" }} className="my-4">
                        <Button type="submit" variant="secondary" className="btn btn-primary btn-block btn-lg p-3 " disabled={formik.isSubmitting} style={{ color: "white" }}>
                          GET QUOTES&nbsp;<i className="bi bi-caret-right-fill"></i>
                        </Button>
                      </div>
                      <p className="mt-2 text-center">Please note these quotes are estimates based on the information provided by you and are subject to review and confirmation.</p>
                    </Col>
                  </Form.Group>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
        <hr className="opacity-100 mb-3" />

        <div className="flex mb-5" style={{ justifyContent: "space-between" }}>
          <div>
            <Button
              type="button"
              style={{ color: "white" }}
              className="btn btn-secondary btn-outline"
              onClick={() => {
                navigate("/quote");
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
              }}>
              <i className="bi bi-caret-left-fill"></i>&nbsp;Back
            </Button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default QuotePage;
