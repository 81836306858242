import React, { useCallback, useEffect, useState } from "react";

import { Formik } from "formik";
import * as yup from "yup";
import { Button, Col, Form, InputGroup, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { QuoteRequest } from "../types";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { InputSpinner } from "../components/InputSpinner";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/store";
import { setOrgdataState } from "../store/organisationSlice";
import { useNavigate } from "react-router-dom";
import { setSubmissionDataState } from "../store/submissionSlice";
import Header from "../components/Header";
import SubHeader from "../components/SubHeader";
import Footer from "../components/Footer";
import "bootstrap-icons/font/bootstrap-icons.css";
import { v4 as uuidv4 } from "uuid";
import { getBaseURL } from "../utils";
import { setForcedQuoteId, setForcedQuoteUuid } from "../store/quoteSlice";

const initialValues: QuoteRequest = {
  transactiontype: "purchase",
  numsellers: 1,
  salepostcode: "",
  saletenure: "freehold",
  saleprice: "",
  numpurchasers: 1,
  purchasepostcode: "",
  purchasetenure: "freehold",
  purchaseprice: "",
  additionalproperty: false,
  firsttimebuyer: false,
  newbuild: false,
  righttobuy: false,
  purchasesharedownership: false,
  salesharedownership: false,
  nonukresident: false,
  purchasemortgage: false,
  salemortgage: false,
  gRecaptchaResponse: "",
  leadUuid: uuidv4()
};

const QuotePage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [reCaptchaToken, setReCaptchaToken] = useState<string>();

  const [quoteid, setQuoteid] = useState<number>();
  const [quoteUuid, setQuoteUuid] = useState<string>();
  const [forcedQId, setForcedQId] = useState<number>();
  const [forcedQUuid, setForcedQUuid] = useState<string>();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const submissionData = useAppSelector((state) => state.submissionReducer.submissionData);
  const dispatch = useAppDispatch();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("yourAction");
    setReCaptchaToken(token);
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  useEffect(() => {
    let qid;
    const q = searchParams.get("q");
    if (q) {
      try {
        qid = Number(q);
      } catch (e) {
        console.error("Error reading quote ID : ", e);
      }
    }
    setQuoteid(qid);

    const uid = searchParams.get("uid");
    if (uid) {
      setQuoteUuid(uid);
    }

    // now do forced id
    let fqid: number | undefined = undefined;
    const fq = searchParams.get("fq");
    if (fq) {
      try {
        fqid = Number(fq);
      } catch (e) {
        console.error("Error reading forced quote ID : ", e);
      }
    }
    setForcedQId(fqid);

    const fuid = searchParams.get("fuid");
    if (fuid) {
      setForcedQUuid(fuid);
    }
  }, [searchParams]);

  useEffect(() => {
    console.log("useEffect: -----------submissionData = ", submissionData);
    if (!submissionData?.leadUuid) {
      const refreshedValues = { ...submissionData, leadUuid: uuidv4() };

      dispatch(setSubmissionDataState(refreshedValues));
    }
  }, [submissionData]);

  useEffect(() => {
    if (!!forcedQId && !!forcedQUuid) {
      dispatch(setForcedQuoteId(forcedQId as number));
      dispatch(setForcedQuoteUuid(forcedQUuid as string));
    } else {
      dispatch(setForcedQuoteId(undefined));
      dispatch(setForcedQuoteUuid(undefined));
    }

    if (!!quoteid && !!quoteUuid) {
      const url = getBaseURL() + "/searchpoint/rest/customerquote/initialize-multi/" + quoteid + "?uid=" + quoteUuid;

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log("Retrieved Data : ", data);
          dispatch(setOrgdataState(data));
        })
        .catch((err) => {
          console.log("Error: ", err.message);
        });
    }
  }, [quoteid, quoteUuid, forcedQId, forcedQUuid]);

  const handleSubmit = async (values: QuoteRequest) => {
    console.log("Submitted : ", JSON.stringify(values, null, 2));

    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    //const token = await executeRecaptcha("yourAction");

    console.log("handlSubmit: token = ", reCaptchaToken);

    const refreshedValues = { ...values, gRecaptchaResponse: reCaptchaToken, quoteid: quoteid, quoteUuid: quoteUuid };

    dispatch(setSubmissionDataState(refreshedValues));
    navigate("/contact");
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const validationSchema = yup.object({
    transactiontype: yup.string().trim().required("Please select the type of transaction"),

    purchasepostcode: yup
      .string()
      .trim()
      .when("transactiontype", {
        is: (transactiontype: string) => {
          return transactiontype === "purchase" || transactiontype === "both" || transactiontype === "remortgage";
        },
        then: (schema) => schema.required("Property postcode is required").min(5, "The postcode is too short").max(10, "The postcode is too long"),
        otherwise: (schema) => schema.nullable()
      }),
    purchaseprice: yup
      .string()
      .trim()
      .when("transactiontype", {
        is: (transactiontype: string) => {
          return transactiontype === "purchase" || transactiontype === "both" || transactiontype === "remortgage";
        },
        then: (schema) => schema.required("Purchase price is required"),
        otherwise: (schema) => schema.nullable()
      }),
    numpurchasers: yup
      .string()
      .trim()
      .when("transactiontype", {
        is: (transactiontype: string) => {
          return transactiontype === "purchase" || transactiontype === "both" || transactiontype === "remortgage";
        },
        then: (schema) => schema.required("Number of purchasers is required").min(1, "Please enter the number of purchasers"),
        otherwise: (schema) => schema.nullable()
      }),
    purchasetenure: yup
      .string()
      .trim()
      .when("transactiontype", {
        is: (transactiontype: string) => {
          return transactiontype === "purchase" || transactiontype === "both" || transactiontype === "remortgage";
        },
        then: (schema) => schema.required("Purchase tenure is required"),
        otherwise: (schema) => schema.nullable()
      }),
    salepostcode: yup.string().when("transactiontype", {
      is: (transactiontype: string) => {
        return transactiontype === "sale" || transactiontype === "both";
      },
      then: (schema) => schema.required("Property postcode is required").min(5, "The postcode is too short").max(10, "The postcode is too long"),
      otherwise: (schema) => schema.nullable()
    }),
    saleprice: yup
      .string()
      .trim()
      .when("transactiontype", {
        is: (transactiontype: string) => {
          return transactiontype === "sale" || transactiontype === "both";
        },
        then: (schema) => schema.required("Sale price is required"),
        otherwise: (schema) => schema.nullable()
      }),
    numsellers: yup
      .string()
      .trim()
      .when("transactiontype", {
        is: (transactiontype: string) => {
          return transactiontype === "sale" || transactiontype === "both";
        },
        then: (schema) => schema.required("Number of sellers is required").min(1, "Please enter the number of sellers"),
        otherwise: (schema) => schema.nullable()
      }),
    saletenure: yup
      .string()
      .trim()
      .when("transactiontype", {
        is: (transactiontype: string) => {
          return transactiontype === "sale" || transactiontype === "both";
        },
        then: (schema) => schema.required("Sale tenure is required"),
        otherwise: (schema) => schema.nullable()
      })
  });

  return (
    <>
      <Header />
      <SubHeader activeStep={0} />
      <div className="container ">
        <div className="mb-5">
          <div>
            <h3>Compare Conveyancing Quotes</h3>
            <h5>Get up to 4 residential conveyancing quotes instantly.</h5>
            <p>Please select the type of transaction (Sale, Purchase etc.) and enter as much details as you can about the property, then click &lsquo;Next&rsquo;.</p>
          </div>
          <Formik initialValues={submissionData ? submissionData : initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {(formik) => {
              console.log("formik value = ", formik.values);
              console.log("formik errors : ", formik.errors);
              return (
                <Form onSubmit={formik.handleSubmit} className="form-horizontal">
                  <Form.Group>
                    <ToggleButtonGroup id="transactiontype" name="transactiontype" aria-label="Transaction Type" type="radio" value={formik.values.transactiontype} className="w-full">
                      <ToggleButton variant={formik.errors.transactiontype ? "outline-danger" : "outline-primary"} name="transactiontype" id="0" value="purchase" onChange={formik.handleChange} onBlur={formik.handleBlur("purchase")}>
                        Purchase Only
                      </ToggleButton>
                      <ToggleButton variant={formik.errors.transactiontype ? "outline-danger" : "outline-primary"} name="transactiontype" id="1" value="sale" onChange={formik.handleChange} onBlur={formik.handleBlur("sale")}>
                        Sale Only
                      </ToggleButton>
                      <ToggleButton variant={formik.errors.transactiontype ? "outline-danger" : "outline-primary"} name="transactiontype" id="2" value="both" onChange={formik.handleChange} onBlur={formik.handleBlur("both")}>
                        Purchase &amp; Sale
                      </ToggleButton>
                      <ToggleButton variant={formik.errors.transactiontype ? "outline-danger" : "outline-primary"} name="transactiontype" id="3" value="remortgage" onChange={formik.handleChange} onBlur={formik.handleBlur("remortgage")}>
                        Remortgage
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <Form.Text className="text-danger">{formik.touched.transactiontype && formik.errors.transactiontype ? <div className="text-danger">{formik.errors.transactiontype}</div> : null}</Form.Text>
                  </Form.Group>
                  <Form.Text className="text-danger">{formik.errors.transactiontype ? <div className="text-danger">{formik.errors.transactiontype}</div> : null}</Form.Text>

                  <Row style={{ padding: 10 }}>
                    {(formik.values.transactiontype == "purchase" || formik.values.transactiontype == "both") && (
                      <Col sm={12} className="well shadow mt-4">
                        <h5>About Your Purchase</h5>
                        <Form.Group as={Row}>
                          <Form.Label column sm={4}>
                            Property Postcode:
                          </Form.Label>
                          <Col sm={8} md={6} lg={4}>
                            <Form.Control type="text" name="purchasepostcode" placeholder="Enter postcode..." value={formik.values.purchasepostcode} onChange={formik.handleChange} isInvalid={!!formik.touched.purchasepostcode && !!formik.errors.purchasepostcode} />
                            <Form.Text className="text-danger">{formik.touched.purchasepostcode && formik.errors.purchasepostcode ? <div className="text-danger">{formik.errors.purchasepostcode}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-4">
                          <Form.Label column sm={4}>
                            Purchase Price:
                          </Form.Label>
                          <Col sm={8} md={6} lg={4}>
                            <InputGroup>
                              <InputGroup.Text>£</InputGroup.Text>
                              <Form.Control type="number" name="purchaseprice" placeholder="Enter purchase price..." value={formik.values.purchaseprice} onChange={formik.handleChange} isInvalid={!!formik.touched.purchaseprice && !!formik.errors.purchaseprice} />
                            </InputGroup>
                            <Form.Text className="text-danger">{formik.touched.purchaseprice && formik.errors.purchaseprice ? <div className="text-danger">{formik.errors.purchaseprice}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-4">
                          <Form.Label column sm={4}>
                            Number of Purchasers:
                          </Form.Label>
                          <Col sm={8}>
                            <InputSpinner
                              type="int"
                              width={120}
                              precision={1}
                              max={99}
                              min={1}
                              step={1}
                              align="center"
                              value={formik.values.numpurchasers as number}
                              onChange={(e: number) => {
                                formik.setFieldValue("numpurchasers", e);
                              }}
                            />

                            <Form.Text className="text-danger">{formik.touched.numpurchasers && formik.errors.numpurchasers ? <div className="text-danger">{formik.errors.numpurchasers}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-4">
                          <Form.Label column sm={4}>
                            Tenure:
                          </Form.Label>
                          <Col sm={8}>
                            <ToggleButtonGroup id="purchasetenure" name="purchasetenure" aria-label="Tenure" type="radio" value={formik.values.purchasetenure}>
                              <ToggleButton variant={!!formik.touched.purchasetenure && !!formik.errors.purchasetenure ? "outline-danger" : "outline-primary"} name="purchasetenure" id="4" value="freehold" onChange={formik.handleChange} onBlur={formik.handleBlur("freehold")}>
                                Freehold
                              </ToggleButton>
                              <ToggleButton variant={formik.touched.purchasetenure && !!formik.errors.purchasetenure ? "outline-danger" : "outline-primary"} name="purchasetenure" id="5" value="leasehold" onChange={formik.handleChange} onBlur={formik.handleBlur("leasehold")}>
                                Leasehold
                              </ToggleButton>
                            </ToggleButtonGroup>

                            <Form.Text className="text-danger">{formik.touched.purchasetenure && formik.errors.purchasetenure ? <div className="text-danger">{formik.errors.purchasetenure}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>
                        <hr style={{ marginTop: 25 }} />
                        <h5>Additional Questions</h5>

                        <Form.Group as={Row} className="mt-1">
                          <Form.Label column sm={4} className="d-none d-md-flex">
                            Mortgage (Purchase):
                          </Form.Label>
                          <Col sm={8} className="mt-2">
                            <Form.Check type="checkbox" name="purchasemortgage" label="Will you be using a mortgage to purchase or part purchase this property?" checked={formik.values.purchasemortgage} onChange={formik.handleChange} isInvalid={!!formik.touched.purchasemortgage && !!formik.errors.purchasemortgage} />
                            <Form.Text className="text-danger">{formik.touched.purchasemortgage && formik.errors.purchasemortgage ? <div className="text-danger">{formik.errors.purchasemortgage}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-1">
                          <Form.Label column sm={4} className="d-none d-md-flex">
                            New Build
                          </Form.Label>
                          <Col sm={8} className="mt-2">
                            <Form.Check type="checkbox" name="newbuild" label="Is the property a new build?" checked={formik.values.newbuild} onChange={formik.handleChange} isInvalid={!!formik.touched.newbuild && !!formik.errors.newbuild} />
                            <Form.Text className="text-danger">{formik.touched.newbuild && formik.errors.newbuild ? <div className="text-danger">{formik.errors.newbuild}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-1">
                          <Form.Label column sm={4} className="d-none d-md-flex">
                            Shared Ownership
                          </Form.Label>
                          <Col sm={8} className="mt-2">
                            <Form.Check type="checkbox" name="purchasesharedownership" label="Are you buying the property in shared ownnership (e.g. 'Help To Buy' or Housing Association)?" checked={formik.values.purchasesharedownership} onChange={formik.handleChange} isInvalid={!!formik.touched.purchasesharedownership && !!formik.errors.purchasesharedownership} />
                            <Form.Text className="text-danger">{formik.touched.purchasesharedownership && formik.errors.purchasesharedownership ? <div className="text-danger">{formik.errors.purchasesharedownership}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-1">
                          <Form.Label column sm={4} className="d-none d-md-flex">
                            Right To Buy
                          </Form.Label>
                          <Col sm={8} className="mt-2">
                            <Form.Check type="checkbox" name="righttobuy" label="Are you using the Government's 'Right To Buy' Scheme?" checked={formik.values.righttobuy} onChange={formik.handleChange} isInvalid={!!formik.touched.righttobuy && !!formik.errors.righttobuy} />
                            <Form.Text className="text-danger">{formik.touched.righttobuy && formik.errors.righttobuy ? <div className="text-danger">{formik.errors.righttobuy}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>

                        <hr style={{ marginTop: 25 }} />
                        <h5>Stamp Duty Calculation</h5>
                        <p>Answering the following questions will help us calculate the amount of Stamp Duty Land Tax payable (Land Transaction Tax in Wales).</p>
                        <Form.Group as={Row} className="mt-1">
                          <Form.Label column sm={4} className="d-none d-md-flex">
                            Additional Property
                          </Form.Label>
                          <Col sm={8} className="mt-2">
                            <Form.Check type="checkbox" name="additionalproperty" label="Is this property being purchased as a 'Buy To Let' or as an additional property?" checked={formik.values.additionalproperty} onChange={formik.handleChange} isInvalid={!!formik.touched.additionalproperty && !!formik.errors.additionalproperty} />
                            <Form.Text className="text-danger">{formik.touched.additionalproperty && formik.errors.additionalproperty ? <div className="text-danger">{formik.errors.additionalproperty}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-1">
                          <Form.Label column sm={4} className="d-none d-md-flex">
                            First Time Buyer
                          </Form.Label>
                          <Col sm={8} className="mt-2">
                            <Form.Check type="checkbox" name="firsttimebuyer" label="Are you a first time buyer?" checked={formik.values.firsttimebuyer} onChange={formik.handleChange} isInvalid={!!formik.touched.firsttimebuyer && !!formik.errors.firsttimebuyer} />
                            <Form.Text className="text-danger">{formik.touched.firsttimebuyer && formik.errors.firsttimebuyer ? <div className="text-danger">{formik.errors.firsttimebuyer}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-1">
                          <Form.Label column sm={4} className="d-none d-md-flex">
                            Non UK Resident
                          </Form.Label>
                          <Col sm={8} className="mt-2">
                            <Form.Check type="checkbox" name="nonukresident" label="Are you a non-UK Resident" checked={formik.values.nonukresident} onChange={formik.handleChange} isInvalid={!!formik.touched.nonukresident && !!formik.errors.nonukresident} />
                            <Form.Text className="text-danger">{formik.touched.nonukresident && formik.errors.nonukresident ? <div className="text-danger">{formik.errors.nonukresident}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>
                      </Col>
                    )}

                    {(formik.values.transactiontype == "sale" || formik.values.transactiontype == "both") && (
                      <Col sm={12} className="well shadow mt-4">
                        <h5>About Your Sale</h5>
                        <Form.Group as={Row}>
                          <Form.Label column sm={4}>
                            Property Postcode:
                          </Form.Label>
                          <Col sm={8}>
                            <Form.Control type="text" name="salepostcode" placeholder="Enter property postcode..." value={formik.values.salepostcode} onChange={formik.handleChange} isInvalid={!!formik.touched.salepostcode && !!formik.errors.salepostcode} />
                            <Form.Text className="text-danger">{formik.touched.salepostcode && formik.errors.salepostcode ? <div className="text-danger">{formik.errors.salepostcode}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-4">
                          <Form.Label column sm={4}>
                            Sale Price:
                          </Form.Label>
                          <Col sm={8}>
                            <InputGroup>
                              <InputGroup.Text>£</InputGroup.Text>
                              <Form.Control type="number" name="saleprice" placeholder="Enter sale price..." value={formik.values.saleprice} onChange={formik.handleChange} isInvalid={!!formik.touched.saleprice && !!formik.errors.saleprice} />
                            </InputGroup>
                            <Form.Text className="text-danger">{formik.touched.saleprice && formik.errors.saleprice ? <div className="text-danger">{formik.errors.saleprice}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-4">
                          <Form.Label column sm={4}>
                            Number of Sellers:
                          </Form.Label>
                          <Col sm={8}>
                            <InputSpinner
                              type="int"
                              width={120}
                              precision={1}
                              max={99}
                              min={1}
                              step={1}
                              align="center"
                              value={formik.values.numsellers as number}
                              onChange={(e: number) => {
                                formik.setFieldValue("numsellers", e);
                              }}
                            />
                            <Form.Text className="text-danger">{formik.touched.numsellers && formik.errors.numsellers ? <div className="text-danger">{formik.errors.numsellers}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-4">
                          <Form.Label column sm={4}>
                            Tenure:
                          </Form.Label>
                          <Col sm={8}>
                            <ToggleButtonGroup id="saletenure" name="saletenure" aria-label="Tenure" type="radio" value={formik.values.saletenure}>
                              <ToggleButton variant={!!formik.touched.saletenure && !!formik.errors.saletenure ? "outline-danger" : "outline-primary"} name="saletenure" id="4" value="freehold" onChange={formik.handleChange} onBlur={formik.handleBlur("freehold")}>
                                Freehold
                              </ToggleButton>
                              <ToggleButton variant={formik.touched.saletenure && !!formik.errors.saletenure ? "outline-danger" : "outline-primary"} name="saletenure" id="5" value="leasehold" onChange={formik.handleChange} onBlur={formik.handleBlur("leasehold")}>
                                Leasehold
                              </ToggleButton>
                            </ToggleButtonGroup>

                            <Form.Text className="text-danger">{formik.touched.saletenure && formik.errors.saletenure ? <div className="text-danger">{formik.errors.saletenure}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>

                        <hr style={{ marginTop: 25 }} />
                        <h6>Additional Questions</h6>
                        <Form.Group as={Row} className="mt-4">
                          <Form.Label column sm={4} className="d-none d-md-flex">
                            Mortgage (Sale):
                          </Form.Label>
                          <Col sm={8} className="mt-2">
                            <Form.Check type="checkbox" name="salemortgage" label="Do you have a mortgage on the property you are selling?" checked={formik.values.salemortgage} onChange={formik.handleChange} isInvalid={!!formik.touched.salemortgage && !!formik.errors.salemortgage} />
                            <Form.Text className="text-danger">{formik.touched.salemortgage && formik.errors.salemortgage ? <div className="text-danger">{formik.errors.salemortgage}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-1">
                          <Form.Label column sm={4} className="d-none d-md-flex">
                            Shared Ownership
                          </Form.Label>
                          <Col sm={8} className="mt-2">
                            <Form.Check type="checkbox" name="salesharedownership" label="Is the property you are selling in shared ownnership (e.g. 'Help To Buy' or Housing Association)?" checked={formik.values.salesharedownership} onChange={formik.handleChange} isInvalid={!!formik.touched.salesharedownership && !!formik.errors.salesharedownership} />
                            <Form.Text className="text-danger">{formik.touched.salesharedownership && formik.errors.salesharedownership ? <div className="text-danger">{formik.errors.salesharedownership}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>
                      </Col>
                    )}

                    {formik.values.transactiontype == "remortgage" && (
                      <Col sm={12} className="well shadow mt-4">
                        <h5>About Your Remortgage</h5>
                        <Form.Group as={Row}>
                          <Form.Label column sm={4}>
                            Property Postcode:
                          </Form.Label>
                          <Col sm={8}>
                            <Form.Control type="text" name="purchasepostcode" placeholder="Enter property postcode..." value={formik.values.purchasepostcode} onChange={formik.handleChange} isInvalid={!!formik.touched.purchasepostcode && !!formik.errors.purchasepostcode} />
                            <Form.Text className="text-danger">{formik.touched.purchasepostcode && formik.errors.purchasepostcode ? <div className="text-danger">{formik.errors.purchasepostcode}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-4">
                          <Form.Label column sm={4}>
                            Property Value:
                          </Form.Label>
                          <Col sm={8}>
                            <InputGroup>
                              <InputGroup.Text>£</InputGroup.Text>
                              <Form.Control type="number" name="purchaseprice" placeholder="Enter property value..." value={formik.values.purchaseprice} onChange={formik.handleChange} isInvalid={!!formik.touched.purchaseprice && !!formik.errors.purchaseprice} />
                            </InputGroup>
                            <Form.Text className="text-danger">{formik.touched.purchaseprice && formik.errors.purchaseprice ? <div className="text-danger">{formik.errors.purchaseprice}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-4">
                          <Form.Label column sm={4}>
                            Number of Applicants:
                          </Form.Label>
                          <Col sm={8}>
                            <InputSpinner
                              type="int"
                              width={120}
                              precision={1}
                              max={99}
                              min={1}
                              step={1}
                              align="center"
                              value={formik.values.numsellers as number}
                              onChange={(e: number) => {
                                formik.setFieldValue("numsellers", e);
                              }}
                            />
                            <Form.Text className="text-danger">{formik.touched.numsellers && formik.errors.numsellers ? <div className="text-danger">{formik.errors.numsellers}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-4">
                          <Form.Label column sm={4}>
                            Tenure:
                          </Form.Label>
                          <Col sm={8}>
                            <ToggleButtonGroup id="purchasetenure" name="purchasetenure" aria-label="Tenure" type="radio" value={formik.values.purchasetenure}>
                              <ToggleButton variant={!!formik.touched.purchasetenure && !!formik.errors.purchasetenure ? "outline-danger" : "outline-primary"} name="purchasetenure" id="4" value="freehold" onChange={formik.handleChange} onBlur={formik.handleBlur("freehold")}>
                                Freehold
                              </ToggleButton>
                              <ToggleButton variant={formik.touched.purchasetenure && !!formik.errors.purchasetenure ? "outline-danger" : "outline-primary"} name="purchasetenure" id="5" value="leasehold" onChange={formik.handleChange} onBlur={formik.handleBlur("leasehold")}>
                                Leasehold
                              </ToggleButton>
                            </ToggleButtonGroup>

                            <Form.Text className="text-danger">{formik.touched.purchasetenure && formik.errors.purchasetenure ? <div className="text-danger">{formik.errors.purchasetenure}</div> : null}</Form.Text>
                          </Col>
                        </Form.Group>
                      </Col>
                    )}

                    <Form.Group as={Row} className="mt-3">
                      <Col sm={{ span: 8, offset: 2 }}>
                        <div style={{ display: "flex", justifyContent: "center" }} className="my-4">
                          <Button type="submit" variant="secondary" className="btn btn-primary btn-block btn-lg p-3 " disabled={formik.isSubmitting} style={{ color: "white" }}>
                            Next&nbsp;<i className="bi bi-caret-right-fill"></i>
                          </Button>
                        </div>
                        <p className="mt-2 text-center">Please note these quotes are estimates based on the information provided by you and is subject to review and confirmation.</p>
                      </Col>
                    </Form.Group>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default QuotePage;
