import * as React from "react";
import { CustomerQuoteResponseDTO, EmailMeRequestDTO, OrgData, QuoteRequest } from "../types";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as yup from "yup";
import { Message } from "primereact/message";
import { getAllByDisplayValue } from "@testing-library/react";
import { getBaseURL } from "../utils";

interface Props {
  orgdata: OrgData;
  submissionData: QuoteRequest;
  result: CustomerQuoteResponseDTO;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

type EmailMeRequestForm = {
  contactName: string;
  contactEmail: string;
};

const EmailMeModal = (props: Props) => {
  const { showModal, setShowModal, orgdata, submissionData, result } = props;
  const [emailSent, setEmailSent] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);
  const [emailFailedReason, setEmailFailedReason] = useState(undefined);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleEmailMeClicked = async (values: EmailMeRequestForm, formik: FormikHelpers<EmailMeRequestForm>) => {
    console.log("Submitted : ", JSON.stringify(values, null, 2));
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("yourAction");

    console.log("handlSubmit: token = ", token);

    const refreshedValues: EmailMeRequestDTO = {
      ...values,
      gRecaptchaResponse: token,
      orgId: result.organisationId,
      orgUuid: result.organisationUuid,
      completedquoteId: result.completedquoteid
    };

    formik.setSubmitting(true);
    setEmailSent(false);
    setEmailFailed(false);
    setEmailFailedReason(undefined);
    const url = getBaseURL() + "/searchpoint/rest/customerquote/emailme-multi";

    console.log("Values Now : ", JSON.stringify(refreshedValues, null, 2));

    fetch(url, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(refreshedValues)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("responseJson: ", responseJson);
        formik.setSubmitting(false);
        setEmailSent(true);
        setShowModal(false);
        //dispatch(setEmailMeState(responseJson));
        //navigate("/result");
        // TODO
        return responseJson;
      })
      .catch((error) => {
        console.error(error);
        formik.setSubmitting(false);
        setEmailFailed(true);
        setShowModal(false);
        setEmailFailedReason(error);
      });
  };

  const validationSchema = yup.object({
    contactName: yup.string().required("Name is required"),
    contactEmail: yup.string().email("Please enter a valid email address").required("Email is required")
  });

  return (
    <>
      {orgdata && (
        <Modal
          size="lg"
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}>
          <Formik initialValues={{ contactName: submissionData.contactName ? submissionData.contactName : "", contactEmail: submissionData.contactEmail ? submissionData.contactEmail : "" }} onSubmit={handleEmailMeClicked} validationSchema={validationSchema}>
            {(formik) => (
              <Form onSubmit={formik.handleSubmit} className="form-horizontal">
                <Modal.Header closeButton>
                  <Modal.Title>Email Quote</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-3">
                  <p>Please confirm your name and email address and we will send you a copy the quote.</p>

                  <Form.Group as={Row} className="mt-3">
                    <Form.Label column sm={2} className="text-right font-bold">
                      Name:
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control type="text" name="contactName" placeholder="Enter your name..." value={formik.values.contactName} onChange={formik.handleChange} isInvalid={!!formik.touched.contactName && !!formik.errors.contactName} />
                      <Form.Text className="text-danger">{formik.touched.contactName && formik.errors.contactName ? <div className="text-danger">{formik.errors.contactName}</div> : null}</Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mt-3">
                    <Form.Label column sm={2} className="text-right font-bold">
                      Email:
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control type="email" name="contactEmail" placeholder="Enter your email..." value={formik.values.contactEmail} onChange={formik.handleChange} isInvalid={!!formik.touched.contactEmail && !!formik.errors.contactEmail} />
                      <Form.Text className="text-danger">{formik.touched.contactEmail && formik.errors.contactEmail ? <div className="text-danger">{formik.errors.contactEmail}</div> : null}</Form.Text>
                      <p className="small italic mt-1">
                        Your data will be handled in accordance with our{" "}
                        <a href={orgdata.privacypolicyurl} target="_BLANK" rel="noreferrer">
                          Privacy Policy.
                        </a>
                        <br />
                        {orgdata.emailtext.footer}
                      </p>
                    </Col>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary-outline"
                    onClick={() => {
                      setShowModal(false);
                    }}>
                    Cancel
                  </Button>
                  <Button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>
                    Send
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
      {emailSent && !emailFailed && <Message severity="info" text={"An email with the full quote details has been sent to: " + submissionData.contactEmail} className=" w-full justify-content-start" />}
      {emailFailed && <Message severity="error" text={"Failed to send email to " + submissionData.contactEmail + ". Please accept our apologies and try again." + emailFailedReason} className=" w-full justify-content-start" />}
    </>
  );
};

export default EmailMeModal;
