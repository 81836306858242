import * as React from "react";

import { Image } from "react-bootstrap";
import { CustomerQuoteResponseDTO } from "../types";
//import { useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "bootstrap-icons/font/bootstrap-icons.css";

type ReviewPanelProps = {
  result: CustomerQuoteResponseDTO;
};

const ReviewPanel = (props: ReviewPanelProps) => {
  const { result } = props;
  // const navigate = useNavigate();

  // function renderStars(ratingscore: number) {
  //   const stars = [];
  //   for (let i = 0; i < ratingscore; i++) {
  //     stars.push(<i className="bi bi-star-fill" style={{ color: "#ffd100", fontSize: "1.5em" }}></i>);
  //   }
  //   return <span style={{ marginRight: 15 }}>{stars}</span>;
  // }

  return (
    <>
      {!!result && !!result.success && !!result.ratingsource && !!result.ratinggrade && !!result.ratingscore && !!result.ratingnumreviews && (
        <div>
          <p className="small italic">
            <span dangerouslySetInnerHTML={{ __html: result.ratingarticle }} />
          </p>
          <h6>
            {result.ratingsource === "Trustpilot" && (
              <>
                <Image src={result.ratingimagesource} style={{ objectFit: "contain", marginRight: 15, width: 120 }} /> Rated {result.ratingscore} - {result.ratinggrade} on {result.ratingsource} ({result.ratingnumreviews} reviews)
              </>
            )}
            {result.ratingsource === "Review Solicitors" && (
              <>
                <Rating initialValue={result.ratingscore} readonly allowFraction={true} size={24} style={{ marginRight: 15 }} />
                Rated {result.ratingscore} - {result.ratinggrade} on {result.ratingsource} ({result.ratingnumreviews} reviews)
              </>
            )}
          </h6>
        </div>
      )}
    </>
  );
};

export default ReviewPanel;
