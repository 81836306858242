import React from "react";
import "./App.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import LeadResultPage from "./pages/LeadResultPage";
import QuoteResultPage from "./pages/QuoteResultPage";
import QuotePage from "./pages/QuotePage";
import ContactPage from "./pages/ContactPage";
import { PrimeReactProvider } from "primereact/api";

const RECAPTCHA_SITE_KEY = "6LdD7LwpAAAAAHpjW5HL-PUqLLrRTLhs0mQK_CfG";

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <PrimeReactProvider>
        <BrowserRouter>
          <Routes>
            <Route index element={<QuotePage />} />
            <Route path="quote" element={<QuotePage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="lead-result" element={<LeadResultPage />} />
            <Route path="quote-result" element={<QuoteResultPage />} />
          </Routes>
        </BrowserRouter>
      </PrimeReactProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
