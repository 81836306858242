import * as React from "react";

import { Button, Col, Row } from "react-bootstrap";
import { OrgData, CustomerQuoteResponseDTO, QuoteRequest } from "../types";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { useEffect, useState } from "react";

import "primereact/resources/themes/lara-light-cyan/theme.css";
import EmailMeModal from "../modals/EmailMeModal";
import ContactMeModal from "../modals/ContactMeModal";

type OrgResultProps = {
  result: CustomerQuoteResponseDTO;
  orgdata: OrgData;
  submissionData: QuoteRequest;
  instructUs: boolean;
};

const ResultPanel = (props: OrgResultProps) => {
  const { result, orgdata, submissionData, instructUs } = props;
  const [showEmailMeModal, setShowEmailMeModal] = useState(false);
  const [showContactMeModal, setShowContactMeModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (instructUs) {
      setShowContactMeModal(true);
    } else {
      setShowContactMeModal(false);
    }
  }, [instructUs]);

  const handleEmailMe = (): void => {
    setShowEmailMeModal(true);
  };
  const handleContactMe = (): void => {
    setShowContactMeModal(true);
  };

  return (
    <>
      {!!result && (
        <div className="mt-5">
          {!result.success && (
            <div>
              <h3>Quote Unavailable</h3>
              <p>Please accept our apologies, we are unable to provide a standard quote for the property details entered.</p>
              <p>{result.errorreason}</p>
              <p>
                Please{" "}
                <a href={orgdata.progressresponsetext.urllink} target="_BLANK" rel="noreferrer">
                  contact us
                </a>{" "}
                directly to discuss your requirements in more detail.
              </p>
            </div>
          )}

          {!!result.success && (
            <div>
              <h3>{orgdata.resulttext.title}</h3>
              <h5>{orgdata.resulttext.subtitle}</h5>
              <p>{orgdata.resulttext.introduction}</p>

              {(result.purchaseFeeList.length || result.purchaseCostList.length) && (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Your Purchase</th>
                      <th className="text-right">Price (excl.VAT)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {result.purchaseFeeList.length && (
                      <tr style={{ backgroundColor: "#f9f9f9" }}>
                        <th className="italic">Conveyancing Fees</th>
                        <th></th>
                      </tr>
                    )}
                    {result.purchaseFeeList.map((thisDocument, index) => (
                      <tr key={index}>
                        <td>{thisDocument.name}</td>
                        {thisDocument.price != 0 && !thisDocument.hasrange && (
                          <td className="text-right">
                            <NumericFormat value={thisDocument.price} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                          </td>
                        )}
                        {thisDocument.price != 0 && thisDocument.hasrange && (
                          <td className="text-right">
                            <NumericFormat value={thisDocument.price} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} /> to <NumericFormat value={thisDocument.maxprice} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                          </td>
                        )}
                        {thisDocument.price === 0 && <td className="text-right">Varies*</td>}
                      </tr>
                    ))}

                    {result.purchaseCostList.length && (
                      <tr style={{ backgroundColor: "#f9f9f9" }}>
                        <th className="italic">Additional Legal Fees and Disbursements</th>
                        <th></th>
                      </tr>
                    )}

                    {result.purchaseCostList.map((thisDocument, index) => (
                      <tr key={index}>
                        <td>{thisDocument.name}</td>
                        {thisDocument.price != 0 && !thisDocument.hasrange && (
                          <td className="text-right">
                            <NumericFormat value={thisDocument.price} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                          </td>
                        )}
                        {thisDocument.price != 0 && thisDocument.hasrange && (
                          <td className="text-right">
                            <NumericFormat value={thisDocument.price} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} /> to <NumericFormat value={thisDocument.maxprice} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                          </td>
                        )}
                        {thisDocument.price === 0 && <td className="text-right">Varies*</td>}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {(!!result.saleFeeList.length || !!result.saleCostList.length) && (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Your Sale</th>
                      <th className="text-right">Price (excl.VAT)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {result.saleFeeList.length && (
                      <tr style={{ backgroundColor: "#f9f9f9" }}>
                        <th className="italic">Conveyancing Fees</th>
                        <th></th>
                      </tr>
                    )}
                    {result.saleFeeList.map((thisDocument, index) => (
                      <tr key={index}>
                        <td>{thisDocument.name}</td>
                        {thisDocument.price != 0 && !thisDocument.hasrange && (
                          <td className="text-right">
                            <NumericFormat value={thisDocument.price} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                          </td>
                        )}
                        {thisDocument.price != 0 && thisDocument.hasrange && (
                          <td className="text-right">
                            <NumericFormat value={thisDocument.price} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} /> to <NumericFormat value={thisDocument.maxprice} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                          </td>
                        )}
                        {thisDocument.price === 0 && <td className="text-right">Varies*</td>}
                      </tr>
                    ))}

                    {result.saleCostList.length && (
                      <tr style={{ backgroundColor: "#f9f9f9" }}>
                        <th className="italic">Additional Legal Fees and Disbursements</th>
                        <th></th>
                      </tr>
                    )}

                    {result.saleCostList.map((thisDocument, index) => (
                      <tr key={index}>
                        <td>{thisDocument.name}</td>
                        {thisDocument.price != 0 && !thisDocument.hasrange && (
                          <td className="text-right">
                            <NumericFormat value={thisDocument.price} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                          </td>
                        )}
                        {thisDocument.price != 0 && thisDocument.hasrange && (
                          <td className="text-right">
                            <NumericFormat value={thisDocument.price} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} /> to <NumericFormat value={thisDocument.maxprice} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                          </td>
                        )}
                        {thisDocument.price === 0 && <td className="text-right">Varies*</td>}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {(!!result.remortgageFeeList.length || !!result.remortgageCostList.length) && (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Your remortgage</th>
                      <th className="text-right">Price (excl.VAT)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {result.remortgageFeeList.length && (
                      <tr style={{ backgroundColor: "#f9f9f9" }}>
                        <th className="italic">Conveyancing Fees</th>
                        <th></th>
                      </tr>
                    )}
                    {result.remortgageFeeList.map((thisDocument, index) => (
                      <tr key={index}>
                        <td>{thisDocument.name}</td>
                        {thisDocument.price != 0 && !thisDocument.hasrange && (
                          <td className="text-right">
                            <NumericFormat value={thisDocument.price} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                          </td>
                        )}
                        {thisDocument.price != 0 && thisDocument.hasrange && (
                          <td className="text-right">
                            <NumericFormat value={thisDocument.price} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} /> to <NumericFormat value={thisDocument.maxprice} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                          </td>
                        )}
                        {thisDocument.price === 0 && <td className="text-right">Varies*</td>}
                      </tr>
                    ))}

                    {result.remortgageCostList.length && (
                      <tr style={{ backgroundColor: "#f9f9f9" }}>
                        <th className="italic">Additional Legal Fees and Disbursements</th>
                        <th></th>
                      </tr>
                    )}

                    {result.remortgageCostList.map((thisDocument, index) => (
                      <tr key={index}>
                        <td>{thisDocument.name}</td>
                        {thisDocument.price != 0 && !thisDocument.hasrange && (
                          <td className="text-right">
                            <NumericFormat value={thisDocument.price} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                          </td>
                        )}
                        {thisDocument.price != 0 && thisDocument.hasrange && (
                          <td className="text-right">
                            <NumericFormat value={thisDocument.price} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} /> to <NumericFormat value={thisDocument.maxprice} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                          </td>
                        )}
                        {thisDocument.price === 0 && <td className="text-right">Varies*</td>}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {result.includesvariables && (
                <p className="italic small">
                  *Varies = The price for this item varies. Please{" "}
                  <a href={orgdata.progressresponsetext.urllink} target="_BLANK" rel="noreferrer">
                    contact us
                  </a>{" "}
                  to discuss.
                </p>
              )}
              <Row style={{ fontSize: "1.25em" }}>
                <Col sm={{ span: 4, offset: 8 }}>
                  <table className="table mt-0">
                    <tbody>
                      <tr>
                        <td className="text-right">NET:</td>
                        {!result.hasrange && (
                          <td className="text-right">
                            <NumericFormat value={result.totalprice} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                          </td>
                        )}
                        {!!result.hasrange && (
                          <td className="text-right">
                            <NumericFormat value={result.totalprice} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} /> to <NumericFormat value={result.maxtotalprice} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                          </td>
                        )}
                      </tr>

                      <tr>
                        <td className="text-right">VAT:</td>
                        {!result.hasrange && (
                          <td className="text-right">
                            <NumericFormat value={result.vatamount} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                          </td>
                        )}
                        {!!result.hasrange && (
                          <td className="text-right">
                            <NumericFormat value={result.vatamount} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} /> to <NumericFormat value={result.maxvatamount} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                          </td>
                        )}
                      </tr>

                      <tr style={{ borderTop: "solid 2px #cccccc", borderBottom: "solid 2px #cccccc" }}>
                        <td className="text-left">
                          <strong>QUOTE TOTAL:</strong>
                        </td>
                        {!result.hasrange && (
                          <td className="text-right">
                            <strong>
                              <NumericFormat value={result.grosstotalprice} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                            </strong>
                          </td>
                        )}
                        {!!result.hasrange && (
                          <td className="text-right">
                            <strong>
                              <NumericFormat value={result.grosstotalprice} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} /> to <NumericFormat value={result.maxgrosstotalprice} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                            </strong>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>

              {(result.sdlt > 0 || result.hmlrfee > 0 || result.ltt > 0) && (
                <div>
                  <h5>Additional Fees</h5>
                  <p>In addition to the above fees, the following costs will also be payable.</p>

                  <table className="table">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th className="text-right">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result.sdlt > 0 && (
                        <tr>
                          <td>Stamp Duty Land Tax (SDLT)</td>
                          <td className="text-right">
                            <strong>
                              <NumericFormat value={result.sdlt} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                            </strong>
                          </td>
                        </tr>
                      )}

                      {result.ltt > 0 && (
                        <tr>
                          <td>Land Transaction Tax (LTT)</td>
                          <td className="text-right">
                            <strong>
                              <NumericFormat value={result.ltt} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                            </strong>
                          </td>
                        </tr>
                      )}

                      {result.hmlrfee > 0 && (
                        <tr>
                          <td>HMLR Registration Fee</td>
                          <td className="text-right">
                            <strong>
                              <NumericFormat value={result.hmlrfee} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                            </strong>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <p className="italic small">
                    {result.hmlrfee > 0 && <span>HMLR</span>}
                    {result.hmlrfee > 0 && result.sdlt > 0 && <span> and </span>}
                    {result.hmlrfee > 0 && result.ltt > 0 && <span> and </span>}
                    {result.sdlt > 0 && <span>SDLT</span>}
                    {result.ltt > 0 && <span>LTT</span>} registration fees are shown for indicative purposes only. Actual fees may vary.
                  </p>
                </div>
              )}
              <hr className="opacity-100 mt-3 mb-3" />
              <p>
                Our reference for this quote is <strong>{result.completedquoteid}</strong>. Please quote this reference in any correspondence.
              </p>

              <hr className="opacity-100 mt-3 mb-3" />
              <div className="clearfix">
                <p className="text-center">{orgdata.resulttext.footer}</p>
              </div>
            </div>
          )}
          <EmailMeModal orgdata={orgdata} submissionData={submissionData} result={result} showModal={showEmailMeModal} setShowModal={setShowEmailMeModal} />
          <ContactMeModal orgdata={orgdata} submissionData={submissionData} result={result} showModal={showContactMeModal} setShowModal={setShowContactMeModal} />
          <hr className="opacity-100 mt-3 mb-3" />

          <div className="d-block d-md-flex " style={{ justifyContent: "space-between" }}>
            <div>
              <Button
                style={{ color: "white" }}
                type="button"
                className="btn btn-secondary btn-outline  my-1 mx-1"
                onClick={() => {
                  navigate("/quote");
                  document.body.scrollTop = 0;
                  document.documentElement.scrollTop = 0;
                }}>
                <i className="bi bi-caret-left-fill"></i>&nbsp;Restart
              </Button>
            </div>
            <div>
              <Button type="button" className="btn btn-primary  my-1 mx-1" onClick={handleContactMe}>
                {orgdata.resulttext.buttontext}
              </Button>

              {!!result.success && (
                <Button type="button" className="btn btn-primary my-1 mx-1" onClick={handleEmailMe}>
                  {orgdata.resulttext.emailmebuttontext}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResultPanel;
