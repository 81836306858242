import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CustomerQuoteResponseDTO, MultiQuoteResponse } from "../types";
import { RootState } from "./store";

export interface IQuoteState {
  multiquote?: MultiQuoteResponse;
  singlequote?: CustomerQuoteResponseDTO;
  selectedQuote?: number;
  forcedQuoteId?: number;
  forcedQuoteUuid?: string;
}

const initialState: IQuoteState = {
  multiquote: undefined,
  singlequote: undefined,
  selectedQuote: undefined,
  forcedQuoteId: undefined,
  forcedQuoteUuid: undefined
};

export const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    setMultiQuoteState: (state, action: PayloadAction<MultiQuoteResponse>) => {
      state.multiquote = action.payload;
    },
    setSingleQuoteState: (state, action: PayloadAction<CustomerQuoteResponseDTO>) => {
      state.singlequote = action.payload;
    },
    clearSelectedQuote: (state) => {
      state.selectedQuote = undefined;
    },
    setSelectedQuote: (state, action: PayloadAction<number>) => {
      state.selectedQuote = action.payload;
    },
    setForcedQuoteId: (state, action: PayloadAction<number | undefined>) => {
      state.forcedQuoteId = action.payload;
    },
    setForcedQuoteUuid: (state, action: PayloadAction<string | undefined>) => {
      state.forcedQuoteUuid = action.payload;
    }
  }
});

export const selectSelectedQuote = createSelector(
  (state: RootState) => state,
  (state) => state.quoteReducer.multiquote?.results?.find((quote) => quote.completedquoteid === state.quoteReducer.selectedQuote)
);

export const selectSingleQuoteResult = createSelector(
  (state: RootState) => state,
  (state) => state.quoteReducer.singlequote
);
export const selectMultiQuoteResult = createSelector(
  (state: RootState) => state,
  (state) => state.quoteReducer.multiquote
);

export const { setMultiQuoteState, setSingleQuoteState, setSelectedQuote, setForcedQuoteId, setForcedQuoteUuid, clearSelectedQuote } = quoteSlice.actions;
export const quoteReducer = quoteSlice.reducer;
