import * as React from "react";
import { ContactMeRequestDTO, CustomerQuoteResponseDTO, OrgData, QuoteRequest } from "../types";
import { Button, Col, Form, Modal, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as yup from "yup";
import { Message } from "primereact/message";
import { getBaseURL } from "../utils";

interface Props {
  orgdata: OrgData;
  submissionData: QuoteRequest;
  result: CustomerQuoteResponseDTO;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

type ContactMeRequestForm = {
  contactName: string;
  contactEmail: string;
  contactTelephone: string;
  contactPreference: string;
  comments: string;
};

const ContactMeModal = (props: Props) => {
  const { showModal, setShowModal, orgdata, submissionData, result } = props;
  const [emailSent, setEmailSent] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);
  const [emailFailedReason, setEmailFailedReason] = useState(undefined);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const validationSchema = yup.object({
    contactName: yup.string().required("Name is required"),
    contactTelephone: yup.string().required("Telephone is required"),
    contactEmail: yup.string().email("Please enter a valid email address").required("Email is required"),
    contactPreference: yup.string().required("Contact preference is required")
  });

  const initialValues: ContactMeRequestForm = {
    contactName: !!submissionData && !!submissionData.contactName ? submissionData.contactName : "",
    contactEmail: !!submissionData && !!submissionData.contactEmail ? submissionData.contactEmail : "",
    contactTelephone: "",
    contactPreference: "",
    comments: ""
  };

  const handleContactMeClicked = async (values: ContactMeRequestForm, formik: FormikHelpers<ContactMeRequestForm>) => {
    console.log("Submitted : ", JSON.stringify(values, null, 2));
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    if (!result) {
      console.log("Selected quote not yet available");
      return;
    }

    const token = await executeRecaptcha("yourAction");

    console.log("handlSubmit: token = ", token);

    const refreshedValue: ContactMeRequestDTO = {
      ...values,
      gRecaptchaResponse: token,
      orgId: result.organisationId,
      orgUuid: result.organisationUuid,
      completedquoteId: result.completedquoteid
    };

    formik.setSubmitting(true);
    setEmailSent(false);
    setEmailFailed(false);
    setEmailFailedReason(undefined);

    const url = getBaseURL() + "/searchpoint/rest/customerquote/contactform-multi";

    console.log("Values Now : ", JSON.stringify(refreshedValue, null, 2));

    fetch(url, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(refreshedValue)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("responseJson: ", responseJson);
        formik.setSubmitting(false);
        setEmailSent(true);
        setShowModal(false);
        //dispatch(setEmailMeState(responseJson));
        //navigate("/result");
        // TODO
        return responseJson;
      })
      .catch((error) => {
        console.error(error);
        formik.setSubmitting(false);
        setEmailFailed(true);
        setShowModal(false);
        setEmailFailedReason(error);
      });
  };

  return (
    <>
      {orgdata && (
        <Modal
          size="xl"
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}>
          <Formik initialValues={initialValues} onSubmit={handleContactMeClicked} validationSchema={validationSchema}>
            {(formik) => (
              <Form onSubmit={formik.handleSubmit} className="form-horizontal">
                <Modal.Header closeButton>
                  <Modal.Title>{orgdata.progresstext.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-3">
                  {!!orgdata && !!submissionData && (
                    <>
                      <p>{orgdata.progresstext.introduction} </p>

                      <Form.Group as={Row} className="mt-3">
                        <Form.Label column sm={2} className="text-right font-bold">
                          Name:
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="text" name="contactName" placeholder="Enter your name..." value={formik.values.contactName} onChange={formik.handleChange} isInvalid={!!formik.touched.contactName && !!formik.errors.contactName} />
                          <Form.Text className="text-danger">{formik.touched.contactName && formik.errors.contactName ? <div className="text-danger">{formik.errors.contactName}</div> : null}</Form.Text>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mt-3">
                        <Form.Label column sm={2} className="text-right font-bold">
                          Email:
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="email" name="contactEmail" placeholder="Enter your email..." value={formik.values.contactEmail} onChange={formik.handleChange} isInvalid={!!formik.touched.contactEmail && !!formik.errors.contactEmail} />
                          <Form.Text className="text-danger">{formik.touched.contactEmail && formik.errors.contactEmail ? <div className="text-danger">{formik.errors.contactEmail}</div> : null}</Form.Text>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mt-3">
                        <Form.Label column sm={2} className="text-right font-bold">
                          Telephone:
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control type="text" name="contactTelephone" placeholder="Enter your telephone number..." value={formik.values.contactTelephone} onChange={formik.handleChange} isInvalid={!!formik.touched.contactTelephone && !!formik.errors.contactTelephone} />
                          <Form.Text className="text-danger">{formik.touched.contactTelephone && formik.errors.contactTelephone ? <div className="text-danger">{formik.errors.contactTelephone}</div> : null}</Form.Text>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mt-3">
                        <Form.Label column sm={2} className="text-right font-bold">
                          Contact Preference:
                        </Form.Label>
                        <Col sm={10}>
                          <ToggleButtonGroup id="contactPreference" name="contactPreference" aria-label="Contact Preference" type="radio" value={formik.values.contactPreference}>
                            <ToggleButton variant={!!formik.touched.contactPreference && !!formik.errors.contactPreference ? "outline-danger" : "outline-primary"} name="contactPreference" id="4" value="telephone" onChange={formik.handleChange} onBlur={formik.handleBlur("telephone")}>
                              Telephone
                            </ToggleButton>
                            <ToggleButton variant={formik.touched.contactPreference && !!formik.errors.contactPreference ? "outline-danger" : "outline-primary"} name="contactPreference" id="5" value="email" onChange={formik.handleChange} onBlur={formik.handleBlur("email")}>
                              Email
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mt-3">
                        <Form.Label column sm={2} className="text-right font-bold">
                          Comments (optional):
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control as="textarea" rows={3} type="text" name="comments" placeholder="Enter comments..." value={formik.values.comments} onChange={formik.handleChange} isInvalid={!!formik.touched.comments && !!formik.errors.comments} />
                          <Form.Text className="text-danger">{formik.touched.comments && formik.errors.comments ? <div className="text-danger">{formik.errors.comments}</div> : null}</Form.Text>
                        </Col>
                      </Form.Group>

                      <Col sm={{ span: 10, offset: 2 }}>
                        <p className="small italic mt-1">
                          Your data will be handled in accordance with our{" "}
                          <a href={orgdata.privacypolicyurl} target="_BLANK" rel="noreferrer">
                            Privacy Policy.
                          </a>
                          <br />
                          {orgdata.progresstext.footer}
                        </p>
                      </Col>
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary-outline"
                    onClick={() => {
                      setShowModal(false);
                    }}>
                    Cancel
                  </Button>
                  <Button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>
                    Send
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
      {emailSent && <Message severity="info" text={"Thanks for your interest in our conveyancing services. A member of our team will contact you shortly"} className=" w-full justify-content-start" />}
      {emailFailed && <Message severity="error" text={"Failed to send message, please accept our apologies and try again. If the problem persists please contact us."} className=" w-full justify-content-start" />}
    </>
  );
};

export default ContactMeModal;
