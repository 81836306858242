import { Stepper, StepperRefAttributes } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import * as React from "react";

interface Props {
  activeStep?: number;
}

const SubHeader = (props: Props) => {
  const stepperRef = React.useRef<StepperRefAttributes>(null);
  const { activeStep } = props;
  return (
    <>
      <div id="content" className="site-content">
        <section id="innerpage_banner" className="innerpage_banner">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumbs_wrap">
                  <a href="https://www.conveyancing-online.co.uk" rel="nofollow">
                    Home /{" "}
                  </a>
                  <span className="innerpage-breadcrumb-title">Instant Quotes</span>
                </div>
                <h1>Instant Quotes</h1>
              </div>
            </div>
          </div>
        </section>

        {activeStep !== undefined && (
          <div className="container my-4 ">
            <div className="d-none d-md-block me-5">
              <Stepper ref={stepperRef} activeStep={activeStep} linear={true}>
                <StepperPanel header=" Property Details"></StepperPanel>
                <StepperPanel header=" Contact Details"></StepperPanel>
                <StepperPanel header=" Quotes"></StepperPanel>
              </Stepper>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SubHeader;
