import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { QuoteRequest } from "../types";
import { v4 as uuidv4 } from "uuid";
export interface ISubmissionState {
  submissionData?: QuoteRequest;
}

const initialState: ISubmissionState = {
  submissionData: {
    numsellers: 1,
    salepostcode: "",
    saletenure: "freehold",
    saleprice: "",
    numpurchasers: 1,
    purchasepostcode: "",
    purchasetenure: "freehold",
    purchaseprice: "",
    additionalproperty: false,
    firsttimebuyer: false,
    newbuild: false,
    righttobuy: false,
    purchasesharedownership: false,
    salesharedownership: false,
    nonukresident: false,
    purchasemortgage: false,
    salemortgage: false,
    gRecaptchaResponse: "",
    leadUuid: uuidv4()
  }
};

export const submissionSlice = createSlice({
  name: "submittedData",
  initialState,
  reducers: {
    setSubmissionDataState: (state, action: PayloadAction<QuoteRequest>) => {
      state.submissionData = action.payload;
    }
  }
});

export const { setSubmissionDataState } = submissionSlice.actions;
export const submissionReducer = submissionSlice.reducer;
