import * as React from "react";

const Header = () => {
  return (
    <>
      <header id="masthead" className="site-header">
        <div className="mobile-cta-menu nitro-lazy">
          <div className="quote-btn-wrapper">
            <a className="hoowla-quote-button" href="#" id="formP5635238" data-id="P5635238" data-person="" data-title="" data-fname="" data-lname="" data-email="" data-phone="" data-send-by-default="" data-validation="false">
              Get Quote
            </a>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="ct-menu-section">
                <div id="ct-logo" className="site-branding">
                  <a href="https://www.conveyancing-online.co.uk/" rel="home" className="custom-logo-link lazyloaded">
                    <img width="350" height="143" alt="The Conveyancing Team Logo" className="custom-logo lazyloaded" decoding="async" id="MTA3OjM1Nw==-1" src="https://www.conveyancing-online.co.uk/wp-content/uploads/2023/03/The-Conveyancing-Team-Logo.svg" />
                  </a>
                </div>
                <div id="ct-menu" className="header-right">
                  <nav id="site-navigation" className="main-navigation">
                    <button className="mobile-button" aria-label="Mobile Menu">
                      <span></span>
                    </button>
                    <div className="menu-quote-wrapper">
                      <div className="menu-home-container">
                        <ul id="primary-menu" className="menu nav-menu" aria-expanded="false">
                          <li id="menu-item-529" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-529">
                            <a href="https://www.conveyancing-online.co.uk/">Home</a>
                          </li>
                          <li id="menu-item-120" className="menu-item menu-item-type-post_type menu-item-object-page ">
                            <a href="https://www.conveyancing-online.co.uk/about-us/">About Us</a>
                          </li>
                          <li id="menu-item-78" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-78">
                            <a href="https://www.conveyancing-online.co.uk/services/">Conveyancing Services</a>
                          </li>
                          <li id="menu-item-222" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-222">
                            <a href="https://www.conveyancing-online.co.uk/faqs/">FAQS</a>
                          </li>
                          <li id="menu-item-131" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-131">
                            <a href="https://www.conveyancing-online.co.uk/blog/">Blog</a>
                          </li>
                          <li id="menu-item-76" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-76">
                            <a href="https://www.conveyancing-online.co.uk/contact-us/">Contact Us</a>
                          </li>
                        </ul>
                      </div>
                      <div className="quote-btn-wrapper">
                        <a className="hoowla-quote-button" href="#" id="formP5635238" data-id="P5635238" data-person="" data-title="" data-fname="" data-lname="" data-email="" data-phone="" data-send-by-default="" data-validation="false">
                          INSTANT QUOTES
                        </a>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
