import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { OrgData } from "../types";
export interface IOrganisationState {
  orgdata?: OrgData;
}

const initialState: IOrganisationState = {
  orgdata: undefined
};

export const organisationSlice = createSlice({
  name: "orgdata",
  initialState,
  reducers: {
    setOrgdataState: (state, action: PayloadAction<OrgData>) => {
      state.orgdata = action.payload;
    }
  }
});

export const { setOrgdataState } = organisationSlice.actions;
export const organisationReducer = organisationSlice.reducer;
