import * as React from "react";
import { useAppDispatch, useAppSelector } from "../store/store";
import ResultPanel from "../components/ResultPanel";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { selectSingleQuoteResult, setSingleQuoteState } from "../store/quoteSlice";
import { useSelector } from "react-redux";
import Header from "../components/Nav";
import SubHeader from "../components/SubHeader";
import Footer from "../components/Footer";
import ResultSummaryPanel from "../components/ResultSummaryPanel";
import { getBaseURL } from "../utils";
import { setOrgdataState } from "../store/organisationSlice";
import { setSubmissionDataState } from "../store/submissionSlice";
import { Spinner } from "react-bootstrap";

const QuoteResultPage = () => {
  const selectedQuote = useSelector(selectSingleQuoteResult);
  const orgdata = useAppSelector((state) => state.orgReducer.orgdata);
  const submissionData = useAppSelector((state) => state.submissionReducer.submissionData);

  const [completedquoteId, setCompletedquoteid] = useState<number>();
  const [quoteUuid, setQuoteUuid] = useState<string>();
  const [instructUs, setInstructUs] = useState<boolean>(false);

  const [fetching, setFetching] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let qid;
    const q = searchParams.get("q");
    if (q) {
      try {
        qid = Number(q);
      } catch (e) {
        console.error("Error reading quote ID : ", e);
      }
    }
    setCompletedquoteid(qid);

    const uid = searchParams.get("uid");
    if (uid) {
      setQuoteUuid(uid);
    }
    const i = searchParams.get("i");
    console.log("i=", i);
    if (i && i === "1") {
      setInstructUs(true);
    } else {
      setInstructUs(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!!completedquoteId && !!quoteUuid) {
      const resultUrl = getBaseURL() + "/searchpoint/rest/customerquote/quote-result?q=" + completedquoteId + "&uid=" + quoteUuid;
      setFetching(true);
      fetch(resultUrl)
        .then((response) => response.json())
        .then((data) => {
          console.log("Retrieved Data : ", data);
          dispatch(setSingleQuoteState(data));
          dispatch(setOrgdataState(data.orgdata));
          dispatch(setSubmissionDataState(data.submissiondata));
          setFetching(false);
        })
        .catch((err) => {
          console.log("Error: ", err.message);
          setFetching(false);
        });
    }
  }, [completedquoteId, quoteUuid]);

  return (
    <>
      <Header />
      <SubHeader activeStep={undefined} />
      <div className="container mx-auto ">
        <div className="max-w-none prose prose-sm">
          <div className="my-5">
            {fetching && (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} className="py-5">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}

            {!fetching && !!selectedQuote && !!orgdata && !!submissionData && (
              <>
                <ResultSummaryPanel result={selectedQuote} summary={false} />
                <ResultPanel result={selectedQuote} orgdata={orgdata} submissionData={submissionData} instructUs={instructUs} />
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default QuoteResultPage;
