import * as React from "react";

import { Button, Card, Image } from "react-bootstrap";
import { CustomerQuoteResponseDTO } from "../types";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { setSelectedQuote } from "../store/quoteSlice";
import { useAppDispatch } from "../store/store";
import ReviewPanel from "./ReviewPanel";

type OrgResultProps = {
  result: CustomerQuoteResponseDTO;
  summary: boolean;
};

const ResultSummaryPanel = (props: OrgResultProps) => {
  const { result, summary } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <>
      {!!result && !!result.success && (
        <Card className="mb-3 shadow" style={{ borderRadius: 8 }}>
          <Card.Body style={{ padding: 0 }}>
            <div className="flex">
              {result.logourl && (
                <div className="flex-1 shadow d-none d-md-flex" style={{ minWidth: 160, display: "flex", alignItems: "center", justifyContent: "center", borderTopLeftRadius: 8, borderBottomLeftRadius: 8, overflow: "hidden" }}>
                  <Image src={result.logourl} style={{ objectFit: "contain", paddingLeft: 15, paddingRight: 15, marginTop: 0, marginBottom: 0 }} />
                </div>
              )}

              <div className="flex-1" style={{ flexGrow: 3, padding: 15 }}>
                <h5 className="mt-0">{result.organisationName}</h5>
                <p>
                  <span dangerouslySetInnerHTML={{ __html: result.organisationAddress }} />
                </p>

                <ReviewPanel result={result} />
                <p className="mb-0 small italic">{result.selectionComments}</p>
              </div>

              <div className="flex-1" style={{ padding: 15 }}>
                <div style={{ display: "flex", textAlign: "right", justifyContent: "space-between", flexDirection: "column", height: "100%" }}>
                  <div style={{ flex: 1 }}>
                    {!result.hasrange && (
                      <h3 className="text-right">
                        <strong>
                          <NumericFormat value={result.grosstotalprice} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                        </strong>
                      </h3>
                    )}
                    {!!result.hasrange && (
                      <h3 className="text-right">
                        <strong>
                          <NumericFormat value={result.grosstotalprice} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} /> to <NumericFormat value={result.maxgrosstotalprice} displayType={"text"} thousandSeparator={true} prefix={"£"} decimalScale={2} fixedDecimalScale={true} />
                        </strong>
                      </h3>
                    )}

                    {result.includesvariables && <p className="italic small">*Varies = The price for this item varies. Please contact us to discuss.</p>}
                  </div>
                  {summary && (
                    <div>
                      <Button
                        type="button"
                        className="btn btn-primary float-right"
                        onClick={() => {
                          dispatch(setSelectedQuote(result.completedquoteid));
                          navigate("/quote-result?q=" + result.completedquoteid + "&uid=" + result.completedquoteuuid);
                          document.body.scrollTop = 0;
                          document.documentElement.scrollTop = 0;
                        }}>
                        View Details
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ResultSummaryPanel;
